import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { MdOrthoUser } from "api/types/mdOrthoUser";
import { useState } from "react";

import { useFormatName } from "common/useFormatName";
import { dateOfBirth } from "@sprint1/pkg/src/date/dateOfBirth";
import { DiagnosisSection } from "../preview/DiagnosisSection";
import { EncounterConfigurationViewModel } from "api/types/encounterConfigurationViewModel";
import { MdOrthoDiagnosis } from "api/types/mdOrthoDiagnosis";
import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";
import { RestrictionSection } from "../preview/RestrictionSection";
import { PhysicalTherapySection } from "../preview/PhysicalTherapySection";
import { PrescriptionSection } from "../preview/PrescriptionSection";
import { ReferralInstructionSection } from "../preview/ReferralInstructionSection";
import { SurgeryInstructionSection } from "../preview/SurgeryInstructionSection";
import { ProviderInfo } from "components/ProviderInfo";
import { PatientInjury } from "api/types/patientInjury";
import { s1Date } from "@sprint1/pkg/src/date";
import { WorkersCompBadge } from "routes/cg/common/WorkersCompBadge";
import { IntakeForm } from "../IntakeForm";
import { BodyPart } from "api/types/bodyPart";
import { GetInjuryQuestionnaireResponse } from "api/types/getInjuryQuestionnaireResponse";
import { Section } from "../preview";
import { PatientRecord } from "../../PatientRecord";
import { PatientMedicalHistory } from "api/types/patientMedicalHistory";
import { MedicalHistoryConfiguration } from "api/types/medicalHistoryConfiguration";
import { toUtcDate } from "common/toUtcDate";
import { getPatientEncounter } from "api/client/patientEncounter/getPatientEncounter";
import { searchUsers } from "api/client/user/searchUsers";
import { userFilters } from "api/helpers/searchUsers.helper";
import { getEncounterViewModel } from "api/client/configuration/getEncounterViewModel";
import { getDiagnoses } from "api/client/configuration/getDiagnoses";
import { getInjury } from "api/client/injury/getInjury";
import { getInjuryQuestionnaire } from "api/client/injury/getInjuryQuestionnaire";
import { getMedicalHistory } from "api/client/patientMedicalHistory/getMedicalHistory";
import { getBodyParts } from "api/client/body/getBodyParts";
import { getMedicalHistoryConfiguration } from "api/client/configuration/getMedicalHistoryConfiguration";
import { routes } from "routes/routes.config";
import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";
import { useToast } from "@sprint1/pkg/src/toast/useToast";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";

export function PrintEncounter() {
  const { translate } = useTranslation();
  const { data: payload } = useData();

  if (!payload) {
    return <ViewPortLoading />;
  }
  return (
    <div className="container">
      <div className="text-end my-2 d-print-none">
        <button
          type="button"
          className="btn btn-sm btn-primary mx-1"
          onClick={() => {
            window.print();
          }}
        >
          {translate("__print")}
        </button>
      </div>
      <div className="row">
        <div className="col ">
          <PatientInfo patient={payload.patient} injury={payload.injury} patientEncounter={payload.patientEncounter} />
          <div className="border border-primary border-2" />
          <div className="row">
            <div className="col-lg-12">
              {payload.questionnaireResponse && payload.injury && (
                <Section title={translate("__intakeForm")}>
                  <IntakeForm
                    injury={payload.injury}
                    questionnaireResponse={payload.questionnaireResponse}
                    bodyParts={payload.bodyParts ?? []}
                  />
                </Section>
              )}
            </div>
          </div>
          <DiagnosisSection
            diagnoses={payload.diagnoses}
            encounterViewModel={payload.encounterViewModel}
            patientEncounter={payload.patientEncounter}
          />

          <PhysicalTherapySection
            encounterViewModel={payload.encounterViewModel}
            patientEncounter={payload.patientEncounter}
          />
          <RestrictionSection
            patientEncounter={payload.patientEncounter}
            encounterViewModel={payload.encounterViewModel}
          />

          <PrescriptionSection
            patientEncounter={payload.patientEncounter}
            encounterViewModel={payload.encounterViewModel}
          />

          <ReferralInstructionSection patientEncounter={payload.patientEncounter} />
          <SurgeryInstructionSection patientEncounter={payload.patientEncounter} />
          <div className="mt-3 border border-primary border-2" />
          <ProviderInfo encounter={payload.patientEncounter} showLogo showSignature />
        </div>
      </div>
    </div>
  );
}
function PatientInfo({
  patient,
  injury,
  patientEncounter,
}: {
  patient: MdOrthoUser;
  injury: PatientInjury | undefined;
  patientEncounter: MdOrthoPatientEncounter;
}) {
  const format = useFormatName();
  const { translate } = useTranslation();
  return (
    <div className="d-flex ">
      <div className="d-flex justify-content-end flex-column m-auto">
        <img src="/public/logo.jpg" alt="logo" style={{ width: "10rem", height: "5rem", objectFit: "scale-down" }} />
      </div>
      <div className="flex-grow-1">
        <div>
          <span className="fw-bold">{format.formatName(patient.lastName, patient.firstName)}</span>
          <span className="ms-2 fw-bold">({dateOfBirth.tryFormat(patient.dateOfBirth)})</span>
        </div>
        {patient.phoneNumber && (
          <div>
            <a className="fw-bold" href={`tel:${patient.phoneNumber}`}>
              {patient.phoneNumber}
            </a>
          </div>
        )}
        <div>
          {patientEncounter.startTime && (
            <span className="fw-bold">
              {translate("__visitDate")}: {s1Date.format.tryFormatDate(patientEncounter.startTime)}
            </span>
          )}

          {injury?.dateOfInjury && (
            <span className="ms-2 fw-bold">
              {translate("__injuryDate")}: {toUtcDate(injury.dateOfInjury)}
            </span>
          )}

          <WorkersCompBadge show={patientEncounter.workmansComp} className="ms-2" />
        </div>
      </div>
    </div>
  );
}

export interface PrintEncounterStateProps {
  patient: MdOrthoUser;
  patientEncounter: MdOrthoPatientEncounter;
  encounterViewModel: EncounterConfigurationViewModel | undefined;
  diagnoses: MdOrthoDiagnosis[] | undefined;
  injury: PatientInjury | undefined;
  bodyParts: BodyPart[] | undefined;
  questionnaireResponse: GetInjuryQuestionnaireResponse | undefined;
  medicalHistory?: PatientMedicalHistory;
  medicalHistoryConfig?: MedicalHistoryConfiguration;
}

function useData() {
  const [data, setData] = useState<PrintEncounterStateProps>();
  const toast = useToast();
  useRunOnMount(() => {
    async function load() {
      const patientEncounterId = routes.careGivers.encounterPrintout.qs();
      if (patientEncounterId) {
        const dataTmp = await getData(patientEncounterId);
        setData(dataTmp);
      } else {
        toast.error({ type: "BadUrl" });
      }
    }
    load();
  });

  return { data };
}

async function getData(patientEncounterId: string): Promise<PrintEncounterStateProps> {
  const { data: patientEncounter } = await getPatientEncounter({ patientEncounterId });
  const injuryId = patientEncounter.patientInjuryId!;
  const { data: usersResponse } = await searchUsers({ request: userFilters.searchUser(patientEncounter.patientId) });
  const patient = usersResponse.results[0];
  const { data: encounterViewModel } = await getEncounterViewModel();
  const { data: diagnoses } = await getDiagnoses({ request: { icd10Codes: [] } });
  const { data: injury } = await getInjury({ injuryId: injuryId });
  const { data: questionnaireResponse } = await getInjuryQuestionnaire({ injuryId: injuryId });
  const { data: medicalHistory } = await getMedicalHistory({ patientId: patientEncounter.patientId });
  const { data: bodyParts } = await getBodyParts();
  const { data: medicalHistoryConfig } = await getMedicalHistoryConfiguration();

  return {
    patientEncounter,
    patient,
    encounterViewModel,
    diagnoses,
    injury,
    questionnaireResponse,
    medicalHistory,
    bodyParts,
    medicalHistoryConfig,
  };
}
